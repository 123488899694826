@import '~stencil/stencil';

.org-switcher-drawer {
  z-index: 22;
  .org-switcher-drawer-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .org-switcher-spinner {
      transform: translateY(100px);
    }
    .org-switcher-current-org {
      margin: 18px;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      text-align: center;
      .org-name {
        margin: 6px 0;
        font-size: 24px;
        font-weight: 600;
      }
    }
    .org-switcher-callout {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 12px;
      padding: $section-margin;
      width: 450px;
      background-color: rgba($intent-warning, 0.2);
      border-radius: 15px;
      color: $intent-warning-active;
      font-size: 15px;
      &-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 6px;
        color: darken($intent-warning-active, 5%);
        .#{$ns}-icon {
          margin-right: 6px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .org-switcher-callout-token-info {
      display: flex;
      align-items: center;
      .#{$ns}-icon {
        color: $intent-primary;
        margin-right: 6px;
      }
    }
    .org-switcher-confirm-button {
      margin-top: 24px;
    }
    .org-switcher-cancel-button {
      margin-top: 12px;
    }
  }
}
.org-switcher-popover {
  z-index: 23;
  .#{$ns}-menu {
    min-width: 300px;
    width: 300px;
    max-height: 300px;
    overflow: auto;
    &-item {
      display: flex;
      flex-direction: column;
      .#{$ns}-text-overflow-ellipsis {
        white-space: unset;
      }
      &-label {
        color: $intent-default;
      }
      &:active,
      &.#{$ns}-active {
        .#{$ns}-menu-item-label {
          color: darken($white, 10%);
        }
      }
    }
  }
}
