@import '~stencil/stencil';

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sso-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    .login-button {
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
    .or {
      margin-top: $section-margin;
      margin-bottom: 24px;
      font-size: 12px;
    }
    &.hidden {
      transform: translateY(-50%) scale(0.1);
      opacity: 0;
      visibility: hidden;
      margin-top: -85px;
    }
  }
  .token-login {
    box-shadow: 0px 0px 2px rgba($intent-default, 0.5);
    background-color: rgba($white, 1);
    height: 40px;
    padding: 0 6px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    .login-input {
      transition: all 0.3s ease-in-out;
      &:hover {
        width: 120px;
      }
      &:focus {
        width: 200px;
      }
      &.expanded {
        width: 250px;
      }
      height: 30px;
      width: 100px;
      border: none;
      box-shadow: none;
      font-size: 12px;
      color: $text-color;
      padding-left: 12px;
      padding-right: 3px;
      border-radius: 24px;
      font-family: $monospace-font;
      // Required to prevent Chrome from changing the background color on autofill
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0px 100px $white inset;
      }
    }
    .login-button {
      padding: 0 15px;
      font-size: 12px;
      height: 24px;
    }
  }
}
