@import '~stencil/stencil';

.table-container {
  border: 1px solid $border-color;
  border-radius: 6px;
  width: auto;
  overflow-x: auto;
  position: relative;
  .loading-spinner {
    background-color: rgba($black, 0.4);
    border-radius: 50%;
    padding: 6px;
    box-shadow: 0 0 6px rgba($black, 0.1);
    .#{$ns}-spinner-track {
      stroke: rgba($black, 0.1) !important;
    }
    .#{$ns}-spinner-head {
      stroke: rgba($white, 0.7) !important;
    }
  }
  &.error,
  &.initial-loading {
    overflow-x: hidden;
    thead {
      visibility: hidden;
    }
  }
  &.secondary-loading {
    overflow-x: hidden;
  }
  &.single-request-mode {
    .table thead th {
      &.voucher-id {
        min-width: 300px;
        max-width: 300px;
      }
    }
  }
  &.related-vouchers-mode {
    .table thead th {
      &.request-id,
      &.voucher-id {
        min-width: 175px;
        max-width: 175px;
      }
    }
  }
  .table {
    overflow-y: auto;
    border-collapse: collapse;
    thead {
      display: table-row;
      th {
        color: $text-color;
        font-weight: 600;
        text-align: center;
        padding: 9px 6px;
        min-width: 110px;
        max-width: 110px;
        border-bottom: 2px solid rgba($light-gray1, 0.6);
        &.selection {
          border-left: 3px solid transparent;
          min-width: 34px;
          max-width: 34px;
          .#{$ns}-checkbox {
            padding: 0;
            margin-bottom: 0;
            .#{$ns}-control-indicator {
              margin: 0;
            }
          }
        }
        &.serial-number {
          min-width: 120px;
          max-width: 120px;
        }
        &.requested-by {
          min-width: 140px;
          max-width: 140px;
        }
        &.request-id,
        &.voucher-id {
          min-width: 150px;
          max-width: 150px;
        }
        &.pdc-organization {
          min-width: 150px;
          max-width: 150px;
        }
        &.os-type {
          min-width: 80px;
          max-width: 80px;
        }
        &.actions {
          min-width: 80px;
          max-width: 80px;
        }
      }
    }
    tbody {
      display: block;
      overflow-x: hidden;
      tr:nth-child(even) {
        background-color: rgba($light-gray1, 0.1);
      }
    }
  }
  .load-failed,
  .no-items-found,
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
