@import '~stencil/stencil';

.request-form-container {
  display: flex;
  flex-direction: column;
  .request-form-options {
    display: flex;
    flex-direction: column;
    .request-form-basic-options {
      display: flex;
      flex-direction: column;
      .certificate-input-type-switch {
        align-self: flex-end;
        max-width: 100%;
        color: $intent-default;
        font-size: 12px;
        margin-right: 1.5px;
        .#{$ns}-control-indicator {
          margin-top: -0.5px;
        }
      }
      .select-certificate-input-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        .select-certificate-input {
          margin-top: 9px;
          margin-left: -1.5px;
          .certificate-select-button {
            display: flex;
            max-width: 100%;
            justify-content: space-between;
            padding-left: 12px;
            .#{$ns}-spinner {
              margin-left: -3px;
              svg {
                width: 15px;
                height: 15px;
              }
            }
            .#{$ns}-button-text {
              width: calc(100% - 27px);
              .selected-certificate-display {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .selected-certificate-identifiers {
                  display: flex;
                  align-items: baseline;
                  .selected-certificate-org {
                    margin-right: 3px;
                  }
                }
                .selected-certificate-info {
                  display: flex;
                  align-items: center;
                  color: lighten($intent-default, 15%);
                  font-size: 12px;
                  .expires-at-icon {
                    margin-right: 6px;
                  }
                  .revocation-checks-icon {
                    border-left: 1px solid lighten($intent-default, 20%);
                    padding-left: 6px;
                    margin-left: 6px;
                    color: lighten($intent-default, 15%);
                  }
                }
              }
            }
          }
        }
        .no-certificates-added-warning {
          margin-top: 6px;
          .#{$ns}-icon {
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .new-certificate-input {
        margin-top: -3px;
      }
    }
    .request-form-advanced-options {
      display: flex;
      flex-direction: column;
    }
  }
  .field {
    width: 100%;
    margin: $section-margin 0;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      align-items: center;
      color: $intent-default;
      &-main {
        display: flex;
        align-items: center;
        &-icon {
          margin-right: 6px;
        }
        &-required {
          margin-left: 3px;
          color: $intent-danger;
        }
        &-info {
          margin-left: 3px;
          &:hover {
            filter: brightness(1.1);
          }
        }
      }
      .clear-selected-certificate {
        margin-left: auto;
        margin-right: -3px;
        .#{$ns}-icon {
          margin-top: -1.5px;
        }
      }
      &-actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        &-spinner {
          margin-top: -6px;
          margin-right: 9px;
        }
        &-clear {
          margin-top: -6px;
          margin-right: 9px;
          cursor: pointer;
          &:hover {
            color: $intent-danger;
          }
        }
        &-file-input {
          resize: vertical;
          margin-bottom: 9px;
        }
      }
    }
    &-value {
      border: 2px solid transparent;
      font-family: $monospace-font;
      font-size: 13px;
      transition:
        transform 0.3s ease-in-out,
        background-color 0.2s ease-in-out;
      &::placeholder,
      input::placeholder {
        font-family: $primary-font;
        color: $light-gray1;
      }
      &.textarea {
        padding: 9px;
        width: 100%;
        max-height: 150px;
        resize: vertical;
      }
      &.taginput {
        max-height: 20vh;
        overflow: auto;
        input {
          padding: 0;
          font-size: inherit;
          font-family: inherit;
        }
      }
      &.drag-highlight {
        transform: scale(0.99);
        transition: all 0.3s ease-in-out;
        border: 2px dashed $intent-primary;
        background-color: rgba($intent-primary, 0.1);
        animation: dragHover 1s ease-in-out infinite alternate-reverse;
      }
    }
  }
  .serial-numbers-validation-enter {
    opacity: 0;
    transform: translateY(-50%);
  }
  .serial-numbers-validation-enter-active {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translateY(0);
  }
  .serial-numbers-validation-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .serial-numbers-validation-exit-active {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(-50%);
  }
  .serial-numbers-validation-text {
    display: flex;
    align-items: center;
    .#{$ns}-tag {
      margin-right: 3px;
      .#{$ns}-icon svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .request-form-divider {
    margin-left: -1.5px;
    margin-top: $section-margin;
    width: 100%;
  }
  .mini-field {
    display: flex;
    align-items: center;
    margin: 6px 0;
    &-header {
      display: flex;
      align-items: center;
      min-width: 150px;
      .#{$ns}-icon {
        margin-right: 6px;
      }
      color: $intent-default;
    }
  }
  .voucher-expiry-selector {
    margin-bottom: 3px;
    .voucher-expiry-selection-button {
      margin-left: 6px;
    }
  }
  .os-type-selector {
    &-button-group {
      margin-left: 9px;
    }
  }
  .override-selector {
    &-toggle {
      margin: 0 9px;
    }
  }
  .security-profile-selector {
    &-toggle {
      margin: 0 9px;
    }
  }
  .request-form-footer {
    display: flex;
    border-top: 1px solid $border-color;
    margin-top: $section-margin;
    padding-top: $section-margin;
    .submit-button {
      margin-left: auto;
      margin-right: -1.5px;
    }
  }
}

.invalid-sno-popover {
  .invalid-serial-numbers-list {
    padding: 9px;
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .#{$ns}-tag {
      margin: 1.5px 0;
    }
  }
}

@keyframes dragHover {
  from {
    opacity: 0.25;
  }
}

@media all and (max-width: 720px) {
  .request-form-container {
    .field {
      margin: 9px 0;
      &-header {
        flex-direction: column;
        &-actions {
          margin: 9px;
          margin-left: unset;
          justify-content: center;
        }
      }
      &-value {
        &.textarea {
          max-height: 80px;
          resize: vertical;
        }
        &.taginput {
          max-height: 20vh;
          overflow: auto;
          input {
            padding: 0;
            font-size: inherit;
            font-family: inherit;
          }
        }
      }
    }
  }
}

@media all and (max-width: 350px) {
  .request-form-container {
    .field {
      &-header {
        &-actions {
          &-file-input {
            width: 85%;
          }
        }
      }
    }
  }
}

.field-header-main-tooltip {
  width: 250px;
}

.select-certificate-popover {
  min-width: 300px;
  .#{$ns}-menu {
    max-height: 275px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .#{$ns}-menu-item {
      .select-item-certificate {
        &-header {
          .select-item-certificate-name {
            color: $intent-default;
            font-size: 13px;
          }
        }
        &-info {
          color: $intent-default;
          font-size: 11px;
          display: flex;
          align-items: center;
          &-left {
            display: flex;
            align-items: center;
            .expires-at-icon {
              margin-right: 3px;
            }
          }
          &-right {
            display: flex;
            align-items: center;
            margin-left: auto;
            .revocation-check-indicator {
              margin-left: 6px;
              color: lighten($intent-default, 20%);
            }
            .expired-indicator {
              margin: 0 3px;
              display: flex;
              align-items: center;
              .#{$ns}-icon {
                margin-right: 3px;
              }
            }
          }
        }
      }
      &:active,
      &.#{$ns}-active {
        .select-item-certificate-name {
          color: darken($white, 10%);
        }
      }
      &.#{$ns}-disabled {
        .select-item-certificate {
          &-name,
          &-info {
            color: lighten($intent-default, 20%);
          }
        }
      }
    }
    .#{$ns}-selected,
    :active {
      .select-item-certificate {
        &-info {
          color: darken($white, 10%);
        }
        .revocation-check-indicator {
          color: darken($white, 20%);
        }
      }
      .select-item-certificate-name {
        color: darken($white, 5%);
      }
    }
  }
}
