@import '~stencil/stencil';

.renderer-status,
.renderer-assertion {
  text-transform: uppercase;
  .#{$ns}-icon svg {
    width: 10px;
    height: 10px;
  }
}
