/* ANIMATION SPEEDS */
.animate__fastest {
  animation-duration: 0.2s;
}

/* POSITIONING CLASSES */
.spinner-position {
  position: absolute !important;
  top: calc(50% - 69px);
  left: 50%;
}

.page-centered {
  position: absolute;
  top: 50%;
  left: calc(50% + 36px);
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media all and (max-width: 720px) {
  .page-centered {
    top: calc(50% - 30px);
    left: 50%;
  }
}
