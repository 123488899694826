@import '~stencil/stencil';

.shared-selector-button {
  min-width: 200px;
  margin-left: -1.5px;
  justify-content: space-between;
  &:disabled > * {
    cursor: not-allowed;
  }
}
.shared-selector-popover .#{$ns}-menu {
  max-height: 300px;
  overflow: auto;
}
