@import '~stencil/stencil';

.row-container {
  transition: background-color 0.2s ease-out;
  &.selected {
    background-color: rgba($intent-primary, 0.1) !important;
    .selection {
      border-left: 3px solid rgba($intent-primary, 0.9);
    }
  }
  &.single-request-mode {
    td {
      &.voucher-id {
        min-width: 300px;
        max-width: 300px;
      }
    }
  }
  &.related-vouchers-mode {
    td {
      &.request-id,
      &.voucher-id {
        min-width: 175px;
        max-width: 175px;
      }
    }
  }
  td {
    padding: 9px 6px;
    min-width: 110px;
    max-width: 110px;
    text-align: center;
    &.selection {
      border-left: 3px solid transparent;
      min-width: 34px;
      max-width: 34px;
      .#{$ns}-checkbox {
        padding: 0;
        margin-bottom: 0;
        .#{$ns}-control-indicator {
          margin: 0;
        }
      }
    }
    &.serial-number {
      min-width: 120px;
      max-width: 120px;
    }
    &.requested-by {
      min-width: 140px;
      max-width: 140px;
      word-wrap: break-word;
      font-size: 12px;
    }
    &.request-id,
    &.voucher-id {
      padding: 0 12px;
      min-width: 150px;
      max-width: 150px;
      font-size: 12px;
    }
    &.request-id,
    &.voucher-id,
    &.serial-number {
      font-family: $monospace-font;
    }
    &.expires-at.expired {
      color: $intent-danger;
    }
    &.pdc-organization {
      min-width: 150px;
      max-width: 150px;
      word-wrap: break-word;
    }
    &.os-type {
      min-width: 80px;
      max-width: 80px;
    }
    &.actions {
      min-width: 80px;
      max-width: 80px;
      .download-button,
      .archive-button {
        margin: 0 3px;
      }
      .archive-button {
        margin-top: -1.5px;
        .#{$ns}-icon svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.row-archive-confirm-popover {
  width: 300px;
  .row-archive-confirmation-container {
    width: 100%;
    margin: 0 auto;
  }
}

@keyframes confirmButtonEffect {
  from {
    filter: brightness(1);
  }
  to {
    filter: brightness(1.1);
  }
}
