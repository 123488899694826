@import '~stencil/stencil';

.security-profile-selector-container {
  width: 100%;
  .security-profile-presets {
    margin-top: 6px;
    margin-bottom: 9px;
    .security-profile-mode {
      text-transform: capitalize;
    }
  }
  .custom-security-profile-mode-header {
    color: $intent-default;
    margin-bottom: 6px;
  }
  .custom-security-profile-mode-settings {
    .security-profile {
      height: 27px;
      display: flex;
      align-items: center;
      margin: 3px 0;
      .#{$ns}-switch,
      .#{$ns}-checkbox {
        margin: 0;
      }
      .security-profile-header {
        margin-right: $section-margin;
      }
      .security-profile-setting {
        margin-left: auto;
      }
    }
  }
}
