.router-error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 100px);
  a {
    text-decoration: none;
  }
}
