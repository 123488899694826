@import '~stencil/stencil';

@media all and (max-width: 600px) {
  .shared-daterange-picker-popover {
    .#{$ns}-datepicker {
      & > :last-child {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media all and (max-width: 375px) {
  .shared-daterange-picker-popover {
    .#{$ns}-datepicker {
      & > :first-child {
        display: none;
      }
      .#{$ns}-divider {
        display: none;
      }
    }
  }
}
