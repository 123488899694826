.bulk-operations-container {
  display: flex;
  align-items: center;
  margin-right: 6px;
  .selection-count {
    min-height: 0;
    padding: 0 6px;
    text-align: center;
  }
}
