@import '~stencil/stencil';

.shared-filters-container {
  margin-bottom: $section-margin;
  display: flex;
  align-items: center;
  .shared-filters-selectors {
    display: flex;
    .shared-selector-button {
      margin-right: 6px;
      min-width: 185px;
      .#{$ns}-icon:first-of-type svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .shared-filters-date-sort {
    display: flex;
    .sort-by-container {
      margin-left: 9px;
      .shared-selector-button {
        min-width: 155px;
      }
    }
    .shared-daterange-picker-button {
      margin-left: 9px;
      min-width: 250px;
    }
  }
}

@media all and (max-width: 1200px) {
  .shared-filters-container:not(.compact) {
    align-items: center;
    flex-wrap: wrap;
    .shared-filters-selectors {
      order: 1;
    }
    .search-bar {
      flex: 1 1 auto;
      margin-bottom: 9px;
    }
    .shared-filters-date-sort {
      margin-left: auto;
      order: 2;
      .sort-by-container {
        .shared-selector-button {
          min-width: 155px;
        }
      }
    }
  }
}

@media all and (max-width: 912px) {
  .shared-filters-container:not(.compact) {
    justify-content: center;
    .shared-filters-selectors {
      margin-bottom: 6px;
    }
    .shared-filters-date-sort {
      margin-left: unset;
    }
  }
}

@media all and (max-width: 460px) {
  .shared-filters-container:not(.compact) {
    flex-direction: column;
    flex-wrap: nowrap;
    .shared-filters-selectors {
      margin-bottom: 6px;
    }
    .shared-filters-date-sort {
      flex-direction: column;
      align-items: center;
      .shared-daterange-picker-button {
        margin-bottom: 6px;
      }
    }
  }
}

@media all and (max-width: 375px) {
  .shared-filters-container:not(.compact) {
    .shared-filters-selectors {
      flex-direction: column;
      margin-bottom: 0;
      .shared-selector-button {
        margin-bottom: 6px;
      }
    }
  }
}

// Compact Mode

@media all and (max-width: 760px) {
  .shared-filters-container.compact {
    align-items: center;
    flex-wrap: wrap;
    .shared-filters-selectors {
      order: 1;
    }
    .search-bar {
      flex: 1 1 auto;
      margin-bottom: 9px;
    }
    .shared-filters-date-sort {
      margin-left: auto;
      order: 2;
      .sort-by-container {
        .shared-selector-button {
          min-width: 155px;
        }
      }
    }
  }
}

@media all and (max-width: 420px) {
  .shared-filters-container.compact {
    flex-direction: column;
    flex-wrap: nowrap;
    .shared-filters-selectors {
      margin-bottom: 6px;
    }
    .shared-filters-date-sort {
      margin-left: unset;
      flex-direction: column;
      align-items: center;
    }
  }
}
