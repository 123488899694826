@import '~stencil/stencil';

.nav-container {
  display: flex;
  align-items: center;
  padding-left: 72px;
  padding-right: 18px;
  box-shadow: 0 0 1px $dark-gray1;
  overflow-x: auto;
  backdrop-filter: blur(3px);
  background-color: rgba($white, 0.85);
  .nav-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .nav-title {
      .nav-breadcrumbs {
        height: unset;
      }
    }
    .nav-subtitle {
      max-width: 60vw;
      color: $intent-default;
      font-size: 12px;
    }
  }
  .nav-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    .nav-actions-desktop {
      display: block;
    }
    .nav-actions-mobile {
      display: none;
      .nav-actions-mobile-target {
        min-height: 0;
        padding: 3px 9px;
      }
    }
  }
}

@media all and (max-width: 720px) {
  .nav-container {
    padding-left: 18px;
    .nav-header {
      .nav-subtitle {
        max-width: 50vw;
      }
    }
  }
}

@media all and (max-width: 460px) {
  .nav-container {
    .nav-header {
      .nav-subtitle {
        max-width: 70vw;
      }
    }
    .nav-actions {
      .nav-actions-desktop {
        display: none;
      }
      .nav-actions-mobile {
        display: flex;
      }
    }
  }
}

.nav-actions-popover .#{$ns}-popover2-content {
  padding: 12px 9px;
  div {
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-gap: 6px;
    justify-items: center;
  }
}
