@import '~stencil/stencil';

.unauthenticated-header-container {
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: rgba($white, 0.85);
  backdrop-filter: blur(3px);
  padding: $section-margin;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 6px 2px rgba($intent-default, 0.3);
  z-index: 1;
  .unauthenticated-header-actions {
    margin-right: auto;
  }
  .unauthenticated-header-title {
    margin-left: -30px;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &-logo {
      .masa-logo {
        width: 48px;
        height: 48px;
        mask: url(../../../assets/img/logo.svg) no-repeat center;
        mask-size: contain;
        background-color: $intent-default;
      }
    }
    &-divider {
      margin: 0 15px;
      height: 32px;
      border-left: 1.5px solid $light-gray1;
    }
    &-name {
      color: $intent-default;
      font-size: 21px;
    }
  }
}
