@import '~stencil/stencil';

.request-form-dialog {
  width: 40vw;
  min-width: 600px;
  max-width: 700px;
  .#{$ns}-dialog-body {
    padding: 0;
    margin: 0 12px;
    .request-form-options {
      padding: 3px;
      max-height: calc(100vh - 220px);
      overflow: auto;
    }
  }
}

@media all and (max-width: 720px) {
  .request-form-dialog {
    margin: 9px;
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding: 3px;
  }
}
