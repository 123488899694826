@import '~stencil/stencil';

.menu-container {
  user-select: none;
  width: 54px;
  height: 100vh;
  background-color: $black;
  position: fixed;
  left: 0;
  z-index: 20;
  padding: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 0.1s ease-out, width 0.1s ease-out,
    box-shadow 0.3s ease-out;
  .logo {
    width: 100%;
    height: 40px;
    mask: url(../../assets/img/logo.svg) no-repeat center;
    mask-size: contain;
    background-color: $white;
    margin-bottom: $section-margin;
  }
  .menu-item {
    .menu-item-link {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 9px;
      margin: 6px 0;
      border-radius: 9px;
      transition: all 0.3s ease-out;
      svg {
        width: 16px;
        height: 24px;
        fill: $white;
      }
      &:hover {
        transform: scale(1.1);
        background-color: rgba($white, 0.6);
        svg {
          fill: rgba($black, 0.6);
        }
      }
      &:active {
        background-color: rgba($white, 0.3);
        svg {
          fill: rgba($black, 0.4);
        }
      }
    }
  }
}
.secondary-section {
  margin-top: auto;
  .menu-item-version {
    text-decoration: none;
    .#{$ns}-tag {
      display: block;
      text-align: center;
      font-size: 10px;
      min-width: unset;
      min-height: unset;
      line-height: unset;
      padding: 1.5px;
      font-weight: bold;
      color: darken($intent-warning, 30%) !important;
    }
  }
}

.menu-item-popover {
  margin-left: 9px !important;
  .#{$ns}-popover2-content {
    padding: 3px 9px;
    background: rgba($black, 0.6);
    color: rgba($white, 0.9);
  }
}

.logout-popover {
  border-radius: 24px;
  opacity: 0.95;
  .#{$ns}-popover2-content {
    border-radius: 24px;
    padding: 0;
  }
}

@media all and (max-width: 720px) {
  .menu-container {
    width: 100vw;
    height: 54px;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 -6px 9px 2px $light-gray1;
    .logo {
      width: 32px;
      margin: 0 9px;
      margin-right: auto;
    }
    .menu-item {
      margin: 0 3px;
    }
    .secondary-section {
      display: flex;
      border-left: 1px solid $intent-default;
      margin-left: 12px;
      padding-left: 12px;
      margin-top: unset;
      .menu-item-version {
        margin-left: 6px;
        align-self: center;
        .#{$ns}-tag {
          padding: 1.5px 6px;
        }
      }
    }
  }
}

@media all and (max-width: 360px) {
  .menu-container {
    .secondary-section {
      .menu-item-version {
        display: none;
      }
    }
  }
}
