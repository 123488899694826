@import '~stencil/stencil';

.sort-by-container {
  display: flex;
  align-items: center;
  .sort-by-order {
    color: $intent-default;
    margin-right: 9px;
    cursor: pointer;
    transition: color 0.3s ease-out;
    &.disabled {
      cursor: not-allowed;
      color: $light-gray1;
    }
    &:not(.disabled) {
      &:hover {
        color: $intent-primary-hover;
      }
      &:active {
        color: $intent-primary-active;
      }
    }
  }
  .shared-selector-button {
    min-width: 100px;
    .#{$ns}-icon {
      margin-right: 0;
    }
  }
}
