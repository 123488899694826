.app-content {
  padding-top: 60px;
  padding-left: 72px;
  padding-bottom: 15px;
  padding-right: 15px;
}

@media all and (max-width: 720px) {
  .app-content {
    padding-left: 15px;
    padding-bottom: 60px;
  }
}
