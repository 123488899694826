@import '~stencil/stencil';

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-height: 100vh;
  padding: $section-margin;
  .masa-logo {
    margin-top: auto;
    margin-bottom: $section-margin;
    width: 100%;
    height: 64px;
    mask: url(../../assets/img/logo.svg) no-repeat center;
    mask-size: contain;
    background-color: $intent-default;
  }
  .landing-footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-links {
      display: flex;
      align-items: center;
      .landing-footer-link {
        text-decoration: none;
        margin-top: $section-margin;
        transition: all 0.3s ease-out;
        margin-right: 6px;
        &:hover {
          transform: scale(1.05);
        }
        &.hidden {
          display: none;
        }
        .#{$ns}-icon {
          svg {
            width: 10px;
            height: 10px;
          }
        }
        &.landing-version {
          .#{$ns}-tag {
            text-align: center;
            min-width: 64px;
            color: darken($intent-warning, 25%) !important;
          }
        }
      }
    }
  }
}
