@import '~stencil/stencil';

body {
  margin: 0;
  background-color: $white;
  color: $black;
  hr {
    border: 0;
    border-top: 1px solid $light-gray1;
  }
}
