@import '~stencil/stencil';

.user-agreement-container {
  height: 100vh;
  padding: $section-margin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .user-agreement-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .masa-logo {
      width: 72px;
      height: 72px;
      mask: url(../../assets/img/logo.svg) no-repeat center;
      mask-size: contain;
      background-color: $intent-default;
    }
    .title {
      margin-top: 9px;
      font-size: 21px;
      color: $intent-primary;
    }
  }
  .user-agreement-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $section-margin 0;
    .agreement-container {
      width: 50vmax;
      max-width: 1000px;
      border: 1px solid $border-color;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6vmin;
      .agreement-message {
        font-size: 2vmin;
        line-height: 1.5em;
        text-align: center;
      }
    }
  }
  .user-agreement-footer {
    .accept-button {
      width: 150px;
      margin-left: 9px;
    }
  }
}

.user-agreement-cancel-popover {
  .user-agreement-cancel-info {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $section-margin;
    .message {
      text-align: center;
      margin-bottom: 6px;
    }
  }
}

@media all and (max-width: 720px) {
  .user-agreement-container {
    .user-agreement-header {
      .title {
        margin-top: 9px;
        font-size: 18px;
      }
    }
    .user-agreement-body {
      .agreement-container {
        width: 70vw;
      }
    }
  }
}
