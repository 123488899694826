@import '~stencil/stencil';

.requests-container {
  max-width: 1268px;
  margin: 0 auto;
  .table-container .table tbody {
    height: calc(100vh - 200px);
  }
  .requests-pagination-controls {
    margin-top: 9px;
    display: flex;
    align-items: center;
    .page-size-selector {
      margin-left: -3px;
      margin-right: 6px;
    }
    .page-control {
      margin-left: auto;
      margin-right: -6px;
      .previous-page {
        margin-right: 6px;
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .requests-container .table-container .table tbody {
    height: calc(100vh - 240px);
  }
}

@media all and (max-width: 916px) {
  .requests-container .table-container .table tbody {
    height: calc(100vh - 270px);
  }
}

@media all and (max-width: 720px) {
  .requests-container .table-container .table tbody {
    height: calc(100vh - 325px);
  }
}

@media all and (max-width: 460px) {
  .requests-container .table-container .table tbody {
    height: calc(100vh - 360px);
  }
}

@media all and (max-width: 375px) {
  .requests-container .table-container .table tbody {
    height: calc(100vh - 395px);
  }
}
