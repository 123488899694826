@import '~stencil/stencil';

.alerts-container {
  position: absolute;
  top: $section-margin;
  width: 80vw;
  max-width: 600px;
  max-height: 100px;
  overflow: auto;
  border-radius: 9px;
  border: 1.5px solid rgba($intent-warning, 0.3);
  padding: 9px $section-margin;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: rgba($intent-warning, 0.2);
  box-shadow: 0 0 9px 3px rgba($intent-warning, 0.2);
  color: darken($intent-warning, 12%);
  .#{$ns}-icon {
    margin-right: $section-margin;
  }
}
