@import '~stencil/stencil';

.archive-confirmation-container {
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 12px;
  .archive-confirmation-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    padding-bottom: 6px;
    border-bottom: 1px solid $border-color;
    &-icon {
      margin: 3px 0;
    }
    &-text {
      margin: 6px 0;
      text-align: center;
      color: $gray4;
    }
  }
  .archive-confirmation-cancel-button,
  .archive-confirmation-confirm-button {
    margin: 3px 0;
  }
}
